import memoizee from "memoizee";
import MobileDetect from "mobile-detect";
import {useEffect, useState} from "react";

/**
 * Checks is the user agent is a mobile device.
 */
const checkUserAgent = (userAgent: string): boolean => {
  const md = new MobileDetect(userAgent);
  return !!md.mobile() || !!md.tablet();
};

/**
 * Memoized to prevent unnecessary string parsing.
 */
const getIsMobileClient = memoizee(() => {
  return checkUserAgent(window.navigator.userAgent);
});

export const useIsMobile = () => {
  const [state, setState] = useState<boolean | undefined>();

  useEffect(() => {
    setState(getIsMobileClient());
  }, []);

  return state;
};
