import React from "react";

const ContentArea = ({
  className,
  children,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  return (
    <div className={`m-auto w-full max-w-7xl ${className}`} {...props}>
      {children}
    </div>
  );
};

export default React.memo(ContentArea);
