import {useCallback, useEffect, useState} from "react";

import {
  getFirstFocusableChild,
  getFocusableChildren,
  getNextFocusableChild,
  getPreviousFocusableChild,
} from "./utils";

export const useArrowKeyFocusScope = (enabled: boolean) => {
  const [focusScope, setFocusScope] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (enabled && focusScope) {
      getFirstFocusableChild(focusScope)?.focus();
    }
  }, [enabled, focusScope]);

  useEffect(() => {
    if (!focusScope) return;

    const tabHandler = (e: KeyboardEvent) => {
      if (e.key === "Tab") {
        e.preventDefault();
      }
    };

    focusScope.addEventListener("keydown", tabHandler);

    return () => {
      focusScope.removeEventListener("keydown", tabHandler);
    };
  }, [focusScope]);

  const getListeners = useCallback((focusScope: HTMLElement) => {
    const focusableChildren = getFocusableChildren(focusScope);

    const topBoundary = (e: KeyboardEvent) => {
      switch (e.key) {
        case "ArrowUp": {
          e.preventDefault();
          break;
        }
        case "ArrowDown": {
          e.preventDefault();
          getNextFocusableChild(focusScope)?.focus();
        }
      }
    };

    const bottomBoundary = (e: KeyboardEvent) => {
      switch (e.key) {
        case "ArrowUp": {
          e.preventDefault();
          getPreviousFocusableChild(focusScope)?.focus();
          break;
        }
        case "ArrowDown": {
          e.preventDefault();
        }
      }
    };

    const moveFocus = (e: KeyboardEvent) => {
      switch (e.key) {
        case "ArrowUp": {
          e.preventDefault();
          getPreviousFocusableChild(focusScope)?.focus();
          break;
        }
        case "ArrowDown": {
          e.preventDefault();
          getNextFocusableChild(focusScope)?.focus();
        }
      }
    };

    const attachListeners = () => {
      focusableChildren.forEach((child, i, array) => {
        if (i === 0) {
          child.addEventListener("keydown", topBoundary);
        } else if (i === array.length - 1) {
          child.addEventListener("keydown", bottomBoundary);
        } else {
          child.addEventListener("keydown", moveFocus);
        }
      });
    };

    const removeListeners = () => {
      focusableChildren.forEach((child, i, array) => {
        if (i === 0) {
          child.removeEventListener("keydown", topBoundary);
        } else if (i === array.length - 1) {
          child.removeEventListener("keydown", bottomBoundary);
        } else {
          child.removeEventListener("keydown", moveFocus);
        }
      });
    };

    return [attachListeners, removeListeners] as const;
  }, []);

  useEffect(() => {
    if (!focusScope) return;
    const [attachListeners, removeListeners] = getListeners(focusScope);

    let remove = removeListeners;
    remove();

    // if any nodes are added to/removed from the focus scope, we need to re-query for focusable nodes and re-attach the listeners
    const observer = new MutationObserver(() => {
      remove();
      const [attachNewListeners, removeNewListeners] = getListeners(focusScope);
      remove = removeNewListeners;
      attachNewListeners();
    });

    if (enabled) {
      observer.observe(focusScope, {
        attributes: false,
        childList: true,
        subtree: true,
      });

      attachListeners();
    }

    return () => {
      remove();
      observer.disconnect();
    };
  }, [enabled, focusScope, getListeners]);

  return {
    focusScope,
    setFocusScope,
  };
};
