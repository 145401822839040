import {Marker} from "@react-google-maps/api";
import {Clusterer} from "@react-google-maps/marker-clusterer";
import React from "react";

const MARKER_DEFAULT_URL = "/static/img/v5/marker2.svg";
const MARKER_ACTIVE_URL = "/static/img/v5/markerActive2.svg";
const HOVER_SCALE = 1.35 as const;
const MARKER_DEFAULT_SIZE = [40, 50] as const;
const MARKER_SCALED_SIZE = [
  MARKER_DEFAULT_SIZE[0] * HOVER_SCALE,
  MARKER_DEFAULT_SIZE[1] * HOVER_SCALE,
] as const;

const getScaledSize = (Size: typeof google.maps.Size, size: readonly [number, number]) =>
  new Size(size[0], size[1]);

const getAnchor = (Point: typeof google.maps.Point, size: readonly [number, number]) =>
  new Point(size[0] / 2, size[1]);

const icons = {
  scaled: {
    url: MARKER_ACTIVE_URL,
    scaledSize: MARKER_SCALED_SIZE,
    anchor: MARKER_SCALED_SIZE,
  },
  default: {
    url: MARKER_DEFAULT_URL,
    scaledSize: MARKER_DEFAULT_SIZE,
    anchor: MARKER_DEFAULT_SIZE,
  },
};

const getIcon = (
  isMarkerActive: boolean,
  Point: typeof google.maps.Point,
  Size: typeof google.maps.Size,
) => {
  const {url, scaledSize, anchor} = isMarkerActive ? icons.scaled : icons.default;
  return {
    url,
    scaledSize: getScaledSize(Size, scaledSize),
    anchor: getAnchor(Point, anchor),
  };
};

type Props = {
  title: string;
  lat: number;
  lng: number;
  clickedOrHovered: boolean;
  onClick?: (e: google.maps.MapMouseEvent) => void;
  clusterer: Clusterer;
};

const MapMarker = ({title, lat, lng, clickedOrHovered, onClick, clusterer}: Props) => (
  <Marker
    title={title}
    clusterer={clusterer}
    position={{
      lat,
      lng,
    }}
    animation={google.maps.Animation.DROP}
    icon={getIcon(clickedOrHovered, google.maps.Point, google.maps.Size)}
    onClick={onClick}
  />
);

export default React.memo(MapMarker);
