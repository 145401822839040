import React, {useEffect, useRef, useState} from "react";
import {Transition, TransitionStatus} from "react-transition-group";
import {EnterHandler, ExitHandler} from "react-transition-group/Transition";

import {useDisclosure} from "../../hooks/useDisclosure";

const getTransitionStyles = (
  state: TransitionStatus,
  contentHeight: number | "auto",
): React.CSSProperties =>
  ({
    entering: {},
    entered: {height: contentHeight},
    exiting: {height: contentHeight},
    exited: {},
    unmounted: {},
  })[state];

const Collapse = ({
  duration = 150,
  children,
  timeout = 0,
  disableAnimation,
  ...transitionProps
}: {
  in?: boolean;
  duration?: number;
  timeout?: number;
  appear?: boolean;
  disableAnimation?: boolean;
  unmountOnExit?: boolean;
  mountOnEnter?: boolean;
  className?: string;
  children: React.ReactNode;
  onEnter?: EnterHandler<undefined>;
  onEntering?: EnterHandler<undefined>;
  onEntered?: EnterHandler<undefined>;
  onExit?: ExitHandler<undefined>;
  onExiting?: ExitHandler<undefined>;
  onExited?: ExitHandler<undefined>;
}): React.ReactElement | null => {
  const [transitionStatus, setTransitionStatus] = useState<TransitionStatus | null>(null);
  const state = useDisclosure();
  const transitionRef = useRef<null>(null);
  const innerRef = useRef<HTMLDivElement | null>(null);
  const styleRef = useRef<HTMLDivElement | null>(null);

  // This effect triggers the style change to `auto` after children enter.
  useEffect(() => {
    switch (transitionStatus) {
      case "entered": {
        setTimeout(state.open, duration);
        break;
      }
      case "exiting": {
        state.close();
        break;
      }
    }
  }, [duration, state, transitionStatus]);

  return (
    <Transition nodeRef={transitionRef} timeout={timeout} {...transitionProps}>
      {transitionStatus => {
        // Update state, but on next tick so that we don't update state while rendering
        setTimeout(() => setTransitionStatus(transitionStatus), 0);
        const wrapperHeight = state.isOpen
          ? "auto"
          : innerRef.current
          ? innerRef.current.clientHeight
          : 0;

        return (
          <div
            ref={styleRef}
            style={{
              transition: `height ${disableAnimation ? 0 : duration}ms ease-in-out`,
              overflow: "hidden",
              height: 0,
              width: "100%",
              ...getTransitionStyles(transitionStatus, wrapperHeight),
            }}
          >
            <div ref={innerRef}>{children}</div>
          </div>
        );
      }}
    </Transition>
  );
};

export default Collapse;
