import {
  LocalStorageKey,
  getFromLocalStorage,
  removeFromLocalStorage,
  setInLocalStorage,
} from "../localStorage";

export const getSkipFitBoundsOnLocationRouteChange = () =>
  getFromLocalStorage(LocalStorageKey.SkipFitBoundsOnLocationsRouteChange);

export const setSkipFitBoundsOnLocationRouteChange = (shouldSkip: boolean) =>
  setInLocalStorage(LocalStorageKey.SkipFitBoundsOnLocationsRouteChange, shouldSkip);

export const unsetSkipFitBoundsOnLocationRouteChange = () =>
  removeFromLocalStorage(LocalStorageKey.SkipFitBoundsOnLocationsRouteChange);
