import {Url} from "next/dist/shared/lib/router/router";
import {useRouter} from "next/router";
import React, {PropsWithChildren, useCallback, useState} from "react";
import {v5Pages} from "src/components/_common/_constants";

import {useActiveIndexController} from "../../../../hooks/useActiveIndexController";
import Button, {ButtonSize, ButtonVariant} from "../../Button";

export type TreeItemId = string | number;

export type TreeItem<T = unknown> = {
  id: TreeItemId;
  children?: TreeItem<T>[];
  data: T;
};

type Props = {
  tree: TreeItem<string>;
  defaultExpandedItems: TreeItemId[];
  defaultSelectedItem: TreeItemId;
  onSelect: (item: TreeItem<string>) => unknown;
};

const Group = ({children}: React.PropsWithChildren<unknown>) => (
  <ul role="group" data-cy="select-dropdown" className="flex flex-col justify-stretch gap-1">
    {children}
  </ul>
);

const ListItem = ({isSelected, children}: PropsWithChildren<{isSelected: boolean}>) => (
  <li
    role="treeitem"
    aria-selected={isSelected}
    className="flex flex-grow flex-shrink-0 flex-col gap-2"
  >
    {children}
  </li>
);

const Root = ({children}: PropsWithChildren<unknown>) => (
  <div
    className="pv3 ph6 mh-6 ovf-y-a ovf-x-a"
    style={{maxHeight: "22rem"}}
    aria-labelledby="react-aria-select-descriptor"
    role="tree"
  >
    {children}
  </div>
);

const ListItemButton = ({
  href,
  onClick,
  item,
  isSelected,
  isExpanded,
  level,
}: {
  isSelected: boolean;
  item: TreeItem<string>;
  onClick: () => void;
  isExpanded: boolean;
  href?: Url;
  level: 1 | 2;
}) => (
  <Button
    size={ButtonSize.SM}
    variant={isSelected ? ButtonVariant.GREEN : ButtonVariant.NAKED}
    href={href}
    onClick={onClick}
    className={`flex-grow ${isSelected ? "" : "hover:bg-gray-100"}`}
    data-cy={`item-${item.id}`}
  >
    <span className={`flex items-center flex-grow justify-between ${level === 2 ? "pl-6" : ""}`}>
      {item.data}
      {item.children ? (
        isExpanded ? (
          <span className="cIcon-dropdown-arrow-up" aria-hidden />
        ) : (
          <span className="cIcon-dropdown-arrow-down" aria-hidden />
        )
      ) : isSelected ? (
        <span className="cIcon-check" aria-hidden />
      ) : null}
    </span>
  </Button>
);

const Tree = ({onSelect, defaultSelectedItem, defaultExpandedItems, tree}: Props) => {
  const {pathname, query} = useRouter();

  const isLocationPage = pathname === v5Pages.locations;
  const expandedItemsController = useActiveIndexController<TreeItemId>(defaultExpandedItems);
  const [selectedItem, setSelectedItem] = useState<TreeItemId>(defaultSelectedItem);

  const handleSelect = useCallback(
    (item: TreeItem<string>) => {
      if (item.children) {
        expandedItemsController.toggle(item.id);
      } else {
        setSelectedItem(item.id);
        onSelect(item);
      }
    },
    [expandedItemsController, onSelect],
  );

  return (
    <Root>
      <Group>
        {tree.children?.map((item, i) => {
          const isSelected = item.id === selectedItem;
          const expandedChildren = expandedItemsController.isActive(item.id) && item.children;
          return (
            <ListItem key={i} isSelected={isSelected}>
              <ListItemButton
                level={1}
                href={
                  isLocationPage && !item.children
                    ? {pathname, query: {...query, region: item.id}}
                    : undefined
                }
                onClick={() => handleSelect(item)}
                item={item}
                isSelected={isSelected}
                isExpanded={expandedItemsController.isActive(i)}
              />
              {expandedChildren ? (
                <Group>
                  {expandedChildren.map((item, i) => {
                    const isSelected = item.id === selectedItem;
                    return (
                      <ListItem key={i} isSelected={isSelected}>
                        <ListItemButton
                          level={2}
                          href={
                            isLocationPage && !item.children
                              ? {pathname, query: {...query, region: item.id}}
                              : undefined
                          }
                          onClick={() => handleSelect(item)}
                          item={item}
                          isSelected={isSelected}
                          isExpanded={expandedItemsController.isActive(i)}
                        />
                      </ListItem>
                    );
                  })}
                </Group>
              ) : null}
            </ListItem>
          );
        })}
      </Group>
    </Root>
  );
};

export default React.memo(Tree);
