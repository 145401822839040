import React from "react";

type Props = Omit<React.HTMLAttributes<HTMLDivElement>, "children"> & {
  children: [React.ReactNode, React.ReactNode];
};

const SplitLayout = ({children, className = "", ...rest}: Props) => (
  <div className={`flex jcsb ${className}`} {...rest}>
    <div className="flex-1">{children[0]}</div>
    <div>{children[1]}</div>
  </div>
);

export default SplitLayout;
