import React from "react";

const Section = ({
  className,
  children,
  ...props
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => {
  return (
    <section className={`px-4 sm:px-8 ${className}`} {...props}>
      {children}
    </section>
  );
};

export default React.memo(Section);
