import {Marker} from "@react-google-maps/api";
import React, {FunctionComponent} from "react";

import {Coordinate} from "../../store/types";

type Props = {
  geoLocation: Coordinate;
};

const CurrentLocationMarker: FunctionComponent<Props> = ({geoLocation}) => (
  <Marker
    key="current-location"
    position={{
      lat: geoLocation?.x,
      lng: geoLocation?.y,
    }}
    icon={{
      path: google.maps.SymbolPath.CIRCLE,
      scale: 20,
      fillOpacity: 0,
      strokeWeight: 0,
    }}
    zIndex={99999}
    title="current-location"
  />
);

export default CurrentLocationMarker;
