/* eslint-disable @typescript-eslint/no-unused-vars */
// @ts-expect-error TS7016: Could not find a declaration file for module 'color'. '/Users/goksel/Sites/carbon-website-next/node_modules/color/index.js' implicitly has an 'any' type.
import Color from "color";
import Link from "next/link";
import React, {ReactElement, memo} from "react";
import {useTypedSelector} from "src/store";

import styles from "./V5Comp.module.scss";

// // we need this to import V5comp.scss styles
// export const CompImporter = withScss(
//   () => <div className="dn">CompImporter</div>,
//   css,
//   "CompImporter",
// );

type Props = {
  // @ts-expect-error TS7008: Member 'id' implicitly has an 'any' type.
  id?;
  // @ts-expect-error TS7008: Member 'children' implicitly has an 'any' type.
  children?;
  // @ts-expect-error TS7008: Member 'bgImage' implicitly has an 'any' type.
  bgImage;
  // @ts-expect-error TS7008: Member 'className' implicitly has an 'any' type.
  className?;
  // @ts-expect-error TS7008: Member 'isSvg' implicitly has an 'any' type.
  isSvg?;
  // @ts-expect-error TS7008: Member 'isJpg' implicitly has an 'any' type.
  isJpg?;
  // @ts-expect-error TS7008: Member 'style' implicitly has an 'any' type.
  style?;
  // @ts-expect-error TS7008: Member 'onClick' implicitly has an 'any' type.
  onClick?;
  alt?: string;
};

export const ImgWithBg: React.FC<Props> = ({
  id = "",
  children,
  bgImage,
  className = "",
  isSvg,
  style,
  isJpg,
  onClick,
  alt,
}) => {
  const {imgExt} = useTypedSelector(({config}) => config);
  const bg = `/static/img/v5${bgImage}${!isSvg && !isJpg ? `.${imgExt}` : ""}`;
  return (
    <div
      id={id}
      className={`${className} ${styles.imgWithBg}`}
      style={{backgroundImage: `url(${bg})`, ...style}}
      onClick={onClick}
      role="img"
      aria-label={alt || bg}
    >
      {children}
    </div>
  );
};

export const Label = ({
  style = {},
  children,
  ...rest
}: {
  style?: React.CSSProperties;
  children: React.ReactNode;
}): ReactElement => (
  <span className={styles.label} style={style} {...rest}>
    {children}
  </span>
);

export const H5 = ({
  children,
  color,
  style = {},
  className = "",
  ...rest
}: {
  children: React.ReactNode;
  color?: string;
  style?: React.CSSProperties;
  className?: string;
}): ReactElement => (
  <h5 className={`${styles.h5} ${className}`} {...rest} style={{"--color": color, ...style}}>
    {children}
  </h5>
);

export interface BtnProps {
  id?: string;
  children?: React.ReactNode;
  className?: string;
  color?: string;
  hover?: string;
  bg?: string;
  hoverBg?: string;
  type?: string;
  fontSize?: string | number;
  lineHeight?: string;
  href?: any;
  bold?: boolean;
  style?: React.CSSProperties;
  as?: string;
  link?: boolean;
  disabled?: boolean;
  onClick?: (e?: React.MouseEvent) => void;
  tabIndex?: number;
  faux?: boolean;
  target?: string;
  "aria-label"?: string;
  rel?: string;
}

export const Btn = memo(
  ({
    children,
    color,
    hover,
    bg,
    hoverBg,
    type,
    fontSize,
    bold,
    href,
    as,
    link = true,
    disabled,
    tabIndex,
    faux = false,
    className = "",
    style = {},
    ...rest
  }: BtnProps): ReactElement => {
    const newHover = hover || color;
    const newHoverBg = hoverBg || Color(bg).lighten(0.3);

    const a = !faux ? (
      <a
        className={`${styles.btn} ${bold ? styles["btn-bold"] : ""} ${
          disabled ? styles["btn-disabled"] : ""
        } ${className}`}
        {...(!link && {href})}
        {...rest}
        style={{
          "--color": color,
          "--hover": newHover,
          "--bg": bg,
          "--hoverBg": newHoverBg,
          ...(fontSize && {
            "--fontSize": typeof fontSize === "number" ? `${fontSize}rem` : fontSize,
          }),
          // ...(lineHeight && {"--lineHeight": lineHeight}),
          ...style,
        }}
        tabIndex={tabIndex}
        data-cy="btn"
      >
        {children}
      </a>
    ) : (
      <span
        className={`${styles.btn} ${bold ? styles["btn-bold"] : ""} ${
          disabled ? styles["btn-disabled"] : ""
        } ${className}`}
        style={{
          "--color": color,
          "--hover": newHover,
          "--bg": bg,
          "--hoverBg": newHoverBg,
          ...(fontSize && {
            "--fontSize": typeof fontSize === "number" ? `${fontSize}rem` : fontSize,
          }),
          ...style,
        }}
      >
        {children}
      </span>
    );

    return link && href ? (
      <Link href={href} as={as} legacyBehavior>
        {a}
      </Link>
    ) : type === "submit" || type === "button" ? (
      <button
        type={type}
        className={`${styles.btn} ${bold ? styles["btn-bold"] : ""} ${className}`}
        {...rest}
        style={{
          "--color": color,
          "--hover": newHover,
          "--bg": bg,
          "--hoverBg": newHoverBg,
          ...(fontSize && {
            "--fontSize": typeof fontSize === "number" ? `${fontSize}rem` : fontSize,
          }),
          ...style,
        }}
        tabIndex={tabIndex}
      >
        {children}
      </button>
    ) : (
      a
    );
  },
);

Btn.displayName = "Btn";
