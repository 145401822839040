import {FocusRing, FocusScope, useFocusManager} from "@react-aria/focus";
import {useFocusWithin} from "@react-aria/interactions";
import React, {useRef} from "react";
// @ts-expect-error TS7016: Could not find a declaration file for module 'react-outside-click-handler'. '/Users/goksel/Sites/carbon-website-next/node_modules/react-outside-click-handler/index.js' implicitly has an 'any' type.
import OutsideClickHandler from "react-outside-click-handler";
import {useDisclosure} from "src/hooks/useDisclosure";
import {onKeyHandler} from "src/useEscape";

import Icon from "./Icon";
import {getMapOptionStyle} from "./Locations/MapOptions";
import {ListOption} from "./OptionsList";
import Fade from "./Transitions/Fade";

const dropdownPositions = {
  "bottom left": {
    right: 0,
    top: "4rem",
  },
  "bottom center": {
    left: "50%",
    transform: "translate(-50%, 0)",
    top: 44,
  },
} as const;

type Props = {
  options: ListOption[];
  onSelect: (value: unknown, index: number) => void;
  text: string;
  variant?: "square" | "round";
  anchor?: keyof typeof dropdownPositions;
};

type OptionProps = {
  option: ListOption;
  onSelect: (value: unknown, index: number) => void;
  order: number;
};

const RadioDropdown: React.FC<Props> = ({options, onSelect, text, anchor = "bottom center"}) => {
  const disclosure = useDisclosure();
  const {focusWithinProps} = useFocusWithin({
    onBlurWithin: disclosure.close,
  });
  const triggerRef = useRef(null);
  const listboxRef = useRef(null);
  const isSelected = options.filter(opt => opt.selected).isEmpty();

  return (
    <div
      className="flex items-center relative"
      onKeyDown={onKeyHandler(["Escape"], e => {
        e.preventDefault();
        disclosure.close();
      })}
      {...focusWithinProps}
    >
      <FocusRing focusRingClass="focus-ring">
        <button
          ref={triggerRef}
          aria-haspopup="listbox"
          aria-expanded={disclosure.isOpen}
          aria-label={`${text}: ${options.filter(x => x.selected)[0]?.text || ""}`}
          className={getMapOptionStyle(!isSelected)}
          onClick={disclosure.toggle}
        >
          <div className="flex items-center justify-between w-36">
            <span className="ellipsis overflow-hidden wsnw">
              {options.filter(x => x.selected)[0]?.text || text}
            </span>
            <Icon
              icon="dropdown-arrow-down"
              className={`text-gray-700 t-100 text-sm leading-none ${
                disclosure.isOpen ? "rotate-180" : ""
              }`}
            />
          </div>
        </button>
      </FocusRing>
      <Fade in={disclosure.isOpen} unmountOnExit>
        <OutsideClickHandler
          onOutsideClick={() => {
            // Otherwise, the parent click event will re-open the list
            setTimeout(disclosure.close, 0);
          }}
        >
          <div
            role="listbox"
            tabIndex={-1}
            aria-label={text}
            ref={listboxRef}
            className="pos-a bg-white p3 dg gafr bs2 br1 fdc zIndex2 gg1 otn-f contrast-tb"
            style={{
              "--br": 0.4,
              display: disclosure.isOpen ? "grid" : "none",
              ...dropdownPositions[anchor],
            }}
            onKeyDown={onKeyHandler(["Escape"], e => {
              e.stopPropagation();
              e.preventDefault();
              disclosure.close();
              // @ts-expect-error TS2531: Object is possibly 'null'.
              triggerRef.current.focus();
            })}
          >
            <FocusScope autoFocus>
              {options.map((opt, i) => (
                <ListboxOption
                  option={opt}
                  order={i}
                  onSelect={onSelect}
                  key={`dropdown-option-${opt.text}`}
                />
              ))}
            </FocusScope>
          </div>
        </OutsideClickHandler>
      </Fade>
    </div>
  );
};

export const ListboxOption: React.FC<OptionProps> = ({option, onSelect, order}) => {
  const focusManager = useFocusManager();
  return (
    <FocusRing focusRingClass="focus-ring">
      <button
        role="option"
        aria-selected={option.selected}
        className="dib wfc pv3 fs14 font-ir wsnw cp brdn bg-transparent aria-focus"
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          onSelect(option.value, order);
        }}
        onKeyDown={onKeyHandler(["ArrowDown", "ArrowUp"], e => {
          if (e.key === "ArrowDown") {
            focusManager.focusNext({wrap: false});
          } else {
            focusManager.focusPrevious({wrap: false});
          }
          e.stopPropagation();
          e.preventDefault();
        })}
      >
        <span
          aria-hidden
          className={`fs10-f mr2 white ${
            option.selected ? "cIcon-check bg-green brd1nc brd-green" : "brd1"
          }`}
          style={{
            display: "inline-block",
            boxSizing: "border-box",
            borderRadius: 2,
            height: 18,
            width: 18,
            verticalAlign: "text-top",
            textAlign: "center",
            lineHeight: "16px",
          }}
        />
        {option.text}
      </button>
    </FocusRing>
  );
};

export default RadioDropdown;
